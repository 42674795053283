const Home = () => import('@/page/home/index.vue')
const Vending = () => import('@/page/vendingCabinet/index.vue')
const Automation = () => import('@/page/automation/index.vue')
const Case = () => import('@/page/case/index.vue')
const we = () => import('@/page/we/index.vue')
const Detail = () => import('@/page/details/index.vue')
const Contact = () => import('@/page/contactus/index.vue')
const Phone = () => import('@/page/contactus/phone/index.vue')
const About = () => import('@/page/contactus/abouts/index.vue')
const Problems = () => import('@/page/contactus/problems/index.vue')
const Guanyume = () => import('@/page/guanyume/index.vue')
const Xchuan = () => import('@/page/guanyume/xchuan/index.vue')
const Honor = () => import('@/page/guanyume/honor/index.vue')

const routes = [
    {
        path: '/', redirect: { name: 'home' }
    },
    {
        path: '/home', name: 'home', component: Home, meta: { title: '首页' }
    },
    {
        path: '/vending', name: 'vending', component: Vending, meta: { title: '售货柜' }
    },
    {
        path: '/automation', name: 'automation', component: Automation, meta: { title: '自动化设备' }
    },
    {
        path: '/case', name: 'case', component: Case, meta: { title: '客户案列' }
    },
    {
        path: '/we', name: 'we', component: we, meta: { title: '关于我们' }
    },
    {
        path: '/contact', name: 'contact', component: Contact, meta: { title: '联系我们' }, redirect: { name: 'phone' },
        children: [
            {
                path: 'phone',
                name: 'phone',
                component: Phone,
                meta: { title: '联系我们' }
            },
            {
                path: 'abouts',
                name: 'abouts',
                component: About,
                meta: { title: '售后服务' }
            },
            {
                path: 'problems',
                name: 'problems',
                component: Problems,
                meta: { title: '常见问题' }
            }
        ]

    },
    {
        path: "/guanyume", name: 'guanyume', component: Guanyume, meta: { title: '关于我们' },
        children: [
            {
                path: 'xchuan',
                name: 'xchuan',
                component: Xchuan,
                meta: { title: '宣传片' }
            },
            {
                path: 'honor',
                name: 'honor',
                component: Honor,
                meta: { title: '证书荣誉' }
            }
        ]
    },
    {
        path: '/details', name: 'details', component: Detail, meta: { title: '商品详情' }
    }
]

export default routes