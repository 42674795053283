<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="less" scoped>
* {
  width: 0;
  height: 0;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
